/**
 * 处理和业务公共相关的
 */

import {
  FormatCurrencyNumberOptions,
  formatCurrency as _formatCurrency,
} from '@shopee_common/currency';
import FaviconIcon from '@/assets/images/close.svg';
import { APP_ENV, isDate, isEmpty, isIOS, isShopeeApp } from './util';
import { monthToInternal } from './constant';
import NP from 'number-precision';
import {
  EDiscountType,
  IActivityInfo,
  IActivityInfoV2,
  IActivityInfoV3,
  KycFlowType,
  RelationShip,
  TActivityType,
  IPremiumDetails,
  PremiumDetailType,
} from '@/types/common';
import { getUrlProductId, transformUrl } from '@/module/url';
import { formatMsg } from '@/locales';

import config from '@/config';
// @ts-ignore
import hmacSHA256 from 'crypto-js/hmac-sha256';
// @ts-ignore
import Base64 from 'crypto-js/enc-base64';
import { v4 as uuidv4 } from 'uuid';
import { call, callWebview } from '@/bridge';

import { getLocalItem, setLocalItem } from '@/module/storage';
/**
 * 相对于 formatCurrencyV2 默认如果传 0 或者 "0" 返回增加货币单位的，比如：Rp0 ，除非特殊场景 传 zeroEmpty，则如果值为0 则返回空
 * @param price
 * @returns
 */
export const formatCurrencyV2 = (
  price?: number | string,
  zeroEmpty?: boolean,
  params?: FormatCurrencyNumberOptions,
): string => {
  if (typeof price == 'undefined' || price === null || price === '') return '';
  if (zeroEmpty && (price === 0 || (price && parseFloat(price as string) === 0))) return '';
  if (isNaN(price as number)) return String(price);
  const env = process.env.REGION.toUpperCase();
  const options = (params || {}) as Object;
  // ph 始终保留并展示两位小数
  if (['th', 'ph'].includes(process.env.REGION)) {
    return _formatCurrency(env as any, price, { precision: 2, ...options });
  }
  if (process.env.REGION === 'sg') {
    return _formatCurrency(env as any, price, { symbol: 'S$', ...options });
  }
  return _formatCurrency(env as any, price, { precision: false, maxPrecision: 3, ...options });
  // return getUtilsByCountry(env as any).formatCurrency(price);
};
// https://npm.garenanow.com/-/web/detail/@shopee_common/currency
/**
 * @deprecated
 * @param price
 * @returns
 */
export const formatCurrency = (price?: number): string => {
  if (!price) return '';
  if (isNaN(price)) return String(price);
  return formatCurrencyV2(price);
  // return getUtilsByCountry(env as any).formatCurrency(price);
};

let currencySymbol: string;
export const getCurrencySymbol = () => {
  if (!currencySymbol) {
    const cur = formatCurrencyV2(1, true, { precision: 0 });
    currencySymbol = cur.replace(/\d+/, '');
  }
  return currencySymbol;
};

let scrollToAnimationTimeout: any = 0;
export function scrollToAnimation(
  to: number,
  step = 10,
  from?: number,
  cb?: Function,
): Promise<void> {
  return new Promise((resolve) => {
    // 计算需要移动的距离
    let currentY = typeof from != 'number' ? window.pageYOffset : from;
    const needScrollTop = to - currentY;
    const dist = Math.ceil(needScrollTop / step);
    clearTimeout(scrollToAnimationTimeout);
    const run = () => {
      scrollToAnimationTimeout = setTimeout(() => {
        currentY += dist;
        let isEnd = false;
        if (dist >= 0 && currentY >= to) {
          currentY = to;
          isEnd = true;
          resolve();
        } else if (dist <= 0 && currentY <= to) {
          currentY = to;
          isEnd = true;
          resolve();
        }
        if (!isEnd) run();
        if (cb) {
          cb(currentY);
        } else {
          window.scrollTo(0, currentY);
        }
      }, 10);
    };
    run();
  });
}

/**
 * 纯函数，无依赖的工具类
 */
/**
 * 将时间转换为格式化时间字符串
 * @param {Number|Datetime|string} timestamp = Date.now()，可以是日期对象、日期字符串、unix时间戳
 * @param {String} formatStr = 'yyyy-MM-dd' 例子：yyyy-MM-dd h:m:s
 * 格式字符串含义：年；y，年；M，月；d,日；h,时；m，分；s,秒；S，毫秒
 * @returns {string}  格式化后的时间字符串
 * @function format
 */

function defaultRender(type: string, data: number, isMonth: boolean) {
  if (isMonth && type == 'M') return monthToInternal(data);
  return data;
}
function defaultChangeMonth(type: string, data: number) {
  return defaultRender(type, data, true);
}
function defaultNoChange(type: string, data: number) {
  return data;
}
type IFormatDataRender = (type: string, data: number) => {};
interface IFormatDataOpt {
  changeMonth?: boolean;
  render?: IFormatDataRender;
  formatStr?: string;
}

// 数字比较 compareNumber('0.1', '<', '0.2')
export function compareNumber(left: string, operator: string, right: string) {
  const difference = NP.minus(left, right);
  switch (operator) {
    case '<':
      return difference < 0;
    case '<=':
      return difference <= 0;
    case '>':
      return difference > 0;
    case '>=':
      return difference >= 0;
    case '==':
    case '===':
      return difference === 0;
    default:
      break;
  }
}

export function formatDate(
  timestamp: number | string | Date = new Date(),
  opt: IFormatDataOpt = {},
) {
  if (!timestamp) return '';
  let newDate: Date;
  let result = opt.formatStr || 'dd MM yyyy';
  let render: IFormatDataRender = defaultNoChange;
  if (typeof opt.changeMonth == 'undefined' || opt.changeMonth) {
    render = defaultChangeMonth;
  } else if (opt.render) {
    render = opt.render;
  }

  if (timestamp instanceof Date) {
    newDate = timestamp;
  } else {
    if (typeof timestamp === 'string') {
      timestamp = timestamp.trim();
      if (!isDate(new Date(timestamp))) {
        timestamp = timestamp.replace(/-/g, '/');
      }
      if (!isNaN(+timestamp)) timestamp = +timestamp;
    } else if (typeof timestamp == 'number') {
      timestamp = new Date(timestamp);
    }
    newDate = new Date(timestamp);
  }
  // 重复调用，如果不是一个合法的Date，则返回原值
  if (!isDate(newDate)) {
    return timestamp as string;
  }
  const dateKey = ['y+', 'M+', 'd+', 'H+', 'm+', 's+', 'S+'];
  function getDateNumberByKey(key: string): any {
    if (key == dateKey[0]) return render('y', newDate.getFullYear());
    if (key == dateKey[1]) return render('M', newDate.getMonth() + 1);
    if (key == dateKey[2]) return render('d', newDate.getDate());
    if (key == dateKey[3]) return render('H', newDate.getHours());
    if (key == dateKey[4]) return render('m', newDate.getMinutes());
    if (key == dateKey[5]) return render('s', newDate.getSeconds());
    if (key == dateKey[6]) return render('S', newDate.getMilliseconds());
  }
  const preSuf = '[/_:\\s-]';
  dateKey.forEach((k) => {
    result = result.replace(new RegExp(`(^|${preSuf})(${k})(${preSuf}|$)`), (m, pre, key, suf) => {
      const r = getDateNumberByKey(k);
      if (key.length == 1 || isNaN(r)) return pre + r + suf;
      return pre + `00${r}`.substr(-key.length) + suf;
    });
  });

  return result;
}

type ISetConfigureParams = {
  title?: string;
  isTransparent?: 1 | 0;
  navbarStyle?: 1 | 0;
  statusBarStyle?: 1 | 0;
} & {
  [K in string]?: any;
};

function getConfigurePageFn() {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  let config: ISetConfigureParams = {};
  let preParams: Record<string, any> = {};
  let executeInterval: any = null;
  const executeQueue: { config: ISetConfigureParams; preParams: Record<string, any> }[] = [];
  return function setConfigurePage(
    navbarParams?: ISetConfigureParams,
    pageParams?: Record<string, any>,
  ) {
    config = { ...config, ...(navbarParams || {}) };
    preParams = { ...preParams, ...(pageParams || {}) };

    const cParams = {
      config,
      preParams,
    };
    executeQueue.push(cParams);
    if (!executeInterval) {
      executeInterval = setInterval(() => {
        // @ts-ignore
        const { config: currentConfig, preParams: currentParams } = executeQueue.shift();
        call('configurePage', {
          navbar: {
            ...currentConfig,
          },
          config: {
            disableReload: 1,
            disableBounce: 1,
            ...currentParams,
          },
        });

        if (!executeQueue.length) {
          clearInterval(executeInterval);
          executeInterval = null;
        }
      }, 100);
    }
  };
}

export const setConfigurePage = getConfigurePageFn();

// 用于触发IOS title更新，随便用个
export function setDocumentTitle(title: string, options: ISetConfigureParams = {}): void {
  document.title = title;
  setConfigurePage({ title, ...options });

  // 不在ShopeeAPP中则进行ifram的方式处理
  if (isIOS() && !isShopeeApp) {
    const iframe = document.createElement('iframe');
    iframe.src = FaviconIcon;
    iframe.style.display = 'none';

    const fn = function () {
      setTimeout(function () {
        iframe.removeEventListener('load', fn);
        document.body.removeChild(iframe);
      });
    };

    iframe.addEventListener('load', fn);
    document.body.appendChild(iframe);
  }
}

export const formatActivityValue = (
  value?: string,
  discount_type?: EDiscountType,
  activity_type?: TActivityType,
) => {
  if (!value || +value === 0) return '';
  switch (discount_type) {
    case EDiscountType.percentage: {
      return `${NP.times(100, value)}%`;
    }
    case EDiscountType.currency: {
      if (activity_type && activity_type === 'Coinback') {
        return value;
      }
      return formatCurrency(+value);
    }
    default:
      return '';
  }
};

/**
 * 处理IActivityInfo、IActivityInfoV2活动展示
 * @param activity_infos 活动信息
 * @returns
 */
export const parseActivityInfo = (
  activity_infos?: IActivityInfoV2[] | IActivityInfo,
): Partial<{
  cashback: string;
  discount: string;
}> => {
  let cashback: string | undefined;
  let discount;
  if (Array.isArray(activity_infos)) {
    activity_infos.forEach((item: IActivityInfoV2) => {
      const { activity_type, fe_cashback, fe_total_discount, fe_actual_cashback, discount_type } =
        item || {};
      switch (activity_type) {
        case 'Cashback':
          cashback = formatActivityValue(fe_actual_cashback, discount_type); // 真返现
          break;
        case 'Direct_Discount':
          discount = formatActivityValue(fe_total_discount, discount_type);
          cashback = cashback || formatActivityValue(fe_cashback, discount_type); // 假返现
          break;
        default:
      }
    });
    // ID motor或者意健险
  } else if (Object.prototype.toString.call(activity_infos) === '[object Object]') {
    cashback = activity_infos?.fe_cashback || '';
    discount = activity_infos?.fe_total_discount || activity_infos?.display_discount;
  }
  return {
    cashback,
    discount,
  };
};

/**
 * 处理IActivityInfoV3活动展示
 * @param activity_infos 活动信息
 * @param display_amount 是否返回确定的金额 默认false
 * @returns
 */
export const parseActivityInfoV2 = (
  activity_infos?: IActivityInfoV3[],
  display_amount?: boolean,
): Partial<{
  cashback: string;
  discount: string;
  coinback: string;
  fakeCashback: string;
}> => {
  let cashback: string | undefined;
  let coinback;
  let discount;
  let fakeCashback;
  (activity_infos || []).forEach((val) => {
    switch (val.activity_type) {
      case 'Direct_Discount':
        if (val.has_fake_cashback) {
          discount = formatActivityValue(val.fake_total_discount, val.discount_type);
          fakeCashback = formatActivityValue(val.fake_cashback, val.discount_type);
        } else {
          discount = display_amount
            ? val.total_discount_amount
            : formatActivityValue(val.total_discount, val.discount_type);
        }
        break;
      case 'Coinback':
        coinback = display_amount
          ? val.total_discount_amount
          : formatActivityValue(val.total_discount, val.discount_type, val.activity_type);
        break;
      case 'Cashback':
        cashback = display_amount
          ? val.total_discount_amount
          : formatActivityValue(val.total_discount, val.discount_type);
        break;
      // eslint-disable-next-line no-fallthrough
      default:
    }
  });
  return {
    cashback,
    coinback,
    discount,
    fakeCashback,
  };
};

export const parseExtraFee = (premiumDetails: IPremiumDetails[]) => {
  let adminFee = '';
  let stampDuty = '';
  (premiumDetails || []).map((item) => {
    if (item.premium_type == PremiumDetailType.ADMIN_FEE) {
      adminFee = item.premium || '';
    }
    if (item.premium_type == PremiumDetailType.STAMP_TAX) {
      stampDuty = item.premium || '';
    }
  });
  return {
    adminFee,
    stampDuty,
  };
};

/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isMY = process.env.REGION === 'my';
/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isPH = process.env.REGION === 'ph';
/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isTH = process.env.REGION === 'th';
/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isID = process.env.REGION === 'id';
/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isVN = process.env.REGION === 'vn';
/**
 * @deprecated 尽量放在页面内处理，避免编译变量引入
 */
export const isBR = process.env.REGION === 'br';
/**
 * 处理公共的 Promotion 底部黄条文案的方法
 * @params msgObject 3个key，分别对应同时有cashback/discount, 只有cashback, 只有discount
 * @params discountObject 2个key, discount和cashback
 * @return 返回对应情况的翻译后的文案
 */

export function getPromotionBottomText(
  msgObject: {
    fullMsg: string;
    onlyCashback: string;
    onlyDiscount: string;
    coinsTail?: string;
  },
  discountObject: { discount?: string; cashback?: string; coins?: string },
): string {
  const { fullMsg, onlyCashback, onlyDiscount, coinsTail } = msgObject;
  const { discount, cashback, coins } = discountObject;
  let finalText = '';
  const discountValid = !(!discount || discount == '0');
  const cashbackValid = !(!cashback || cashback == '0');
  const coinsValid = !(!coins || coins == '0');
  const coinsUnit = coinsTail || 'coins';
  console.log(msgObject, discountObject);
  if (discountValid && (cashbackValid || coinsValid)) {
    finalText = fullMsg.replace('{discount}', discount);
    if (cashbackValid) {
      finalText = finalText.replace(
        '{cashback}',
        coinsValid ? ` ${coins} ${coinsUnit} & ${cashback}` : cashback,
      );
    } else if (coinsValid) {
      finalText = finalText.replace('{cashback}', ` ${coins} ${coinsUnit}`);
    }
  }

  if (discountValid && !cashbackValid && !coinsValid) {
    finalText = onlyDiscount.replace('{discount}', discount);
  }

  if ((cashbackValid || coinsValid) && !discountValid) {
    if (cashbackValid) {
      finalText = onlyCashback.replace(
        '{cashback}',
        coinsValid ? ` ${coins} ${coinsUnit} & ${cashback}` : cashback,
      );
    } else if (coinsValid) {
      finalText = onlyCashback.replace('{cashback}', ` ${coins} ${coinsUnit}`);
    }
  }

  return finalText;
}

/**
 * NOTE: TMSTH broker的车险，起始时间若为2024-2-29,结束时间是2025-3-1
// 其余broker MSIG/CHUBB 起始时间若为2024-2-29,结束时间是2025-2-28
 */
export const getTHEstimateDate = (inception_date: Date) => {
  let newDate = new Date(inception_date);
  const broker = ['1672933236801558528', '1672933236801558530'];
  if (
    broker.includes(getUrlProductId()) &&
    newDate.getDate() === 29 &&
    newDate.getMonth() + 1 === 2
  ) {
    newDate.setDate(newDate.getDate() + 365);
  } else {
    newDate = new Date(newDate.getFullYear() + 1, newDate.getMonth(), newDate.getDate());
  }
  return newDate;
};

export const getCashbackInfo = (params: { cashback: string; coinback: string }) => {
  const { cashback, coinback } = params || {};
  const cashbackText = +cashback ? formatCurrency(+cashback) : '';
  const coinbackText = +coinback
    ? formatMsg('checkout.cashbackinfo.coin', { coin: +coinback })
    : '';
  const value =
    cashbackText && coinbackText
      ? `${cashbackText} & ${coinbackText}`
      : cashbackText + coinbackText;
  const title =
    +coinback && !cashback ? formatMsg('common.CoinCashback') : formatMsg('common.Cashback');
  let content = '';
  if (cashbackText && coinbackText) {
    content = `${formatMsg('checkout.cashback.popupcontent')}\n
    ${formatMsg('checkout.coinCashback.popupcontent')}`;
  } else if (cashbackText) {
    content = formatMsg('checkout.cashback.popupcontent');
  } else {
    content = formatMsg('checkout.coinCashback.popupcontent');
  }
  return { value, title, content };
};

/**
 *
 * @param activities
 * @param preData 直接传计算后的活动金额
 * @returns
 */
export function getRenewPromotionBarText(
  activities: IActivityInfoV2[] | IActivityInfoV3[],
  preData?: Partial<{
    discount: string;
    cashback: string;
    coinback: string;
  }>,
) {
  if (activities?.length === 0 && isEmpty(preData)) {
    return '';
  }
  const data = preData || parseActivityInfoV2(activities, true);
  const { discount, cashback, coinback } = data || {};
  let discountText;
  let coinbackText;
  let cashbackText;
  if (discount && +discount) {
    discountText = formatMsg('discount.off', {
      discount: formatCurrency(+discount),
    });
  }
  if (cashback && +cashback) {
    cashbackText = formatMsg('discount.cashback', {
      cashback: formatCurrency(+cashback),
    });
  }
  if (coinback && +coinback) {
    coinbackText = formatMsg('discount.coinback', {
      coinback: +coinback,
    });
  }

  let str = [discountText, cashbackText, coinbackText].filter((val) => val).join(' + ');

  if (str) str = `<span style="color: #EE4D2D">${str}</span>`;

  return str;
}

// 跳转kyc
export const callKycWebview = (
  type: 'add' | 'edit',
  {
    kyc_type,
    relation,
    member_id,
    is_adult,
  }: {
    kyc_type?: KycFlowType;
    relation: keyof typeof RelationShip;
    member_id?: string;
    is_adult?: string;
  },
) => {
  if (type === 'edit') {
    callWebview({
      url: transformUrl({
        pathname: '/ins/kyc-edit.html',
        search: {
          member_id: member_id || '0',
          is_adult: is_adult || '',
          relation,
          // kyc_bi_type: BIConfig?.type,
        },
      }),
    });
  } else {
    callWebview({
      url: transformUrl({
        pathname: '/ins/kyc.html',
        search: {
          member_id: member_id || '0',
          is_adult: is_adult || '',
          relation,
          kyc_type: kyc_type || '',
          // kyc_bi_type: BIConfig?.type,
        },
      }),
    });
  }
};

// 1: camera 2: gallery 3: pdf-gallery
export enum UploadFileSource {
  CAMERA = 1,
  ALBUM = 2,
  PDF = 3,
}

export enum EFileType {
  PDF = 'pdf',
  Img = 'img',
}

function getSignature(str: string, secretKey: string) {
  return Base64.stringify(hmacSHA256(str, secretKey));
}

export function getFileContent(data: { file_key: string }): string {
  const { file_key } = data;
  const { appId, secretKey } = config;
  const uuid = uuidv4();
  const timestamp = Date.now();
  const req = {
    'X-Req-AppId': appId,
    'X-Req-Nonce': uuid,
    'X-Req-TraceId': uuid,
    'X-Req-AppEnv': APP_ENV,
    'X-Req-Timestamp': timestamp,
    file_key,
  };
  const str = Object.keys(req)
    .sort()
    .reduce((pre, now) => {
      // @ts-ignore
      if (!pre) return `${now}=${req[now]}`;
      // @ts-ignore
      return `${pre}&${now}=${req[now]}`;
    }, '');

  return `${config.uploadHost}/api/h5/get_file?${str}&X-Req-Signature=${getSignature(
    str,
    secretKey,
  )}`;
}

export const previewFile = (file_key: string, opt?: { page_title?: string }) => {
  const fileType = file_key.replace(/.+\./, '');
  switch (fileType) {
    case 'pdf': {
      callWebview({
        url: transformUrl({
          pathname: '/ins/pdf-viewer.html',
          search: {
            url: getFileContent({ file_key }),
            page_title: 'PDF',
          },
        }),
      });
      break;
    }
    case 'jpg':
    case 'jpeg':
    case 'png': {
      if (isIOS() && process.env.REGION == 'ph') {
        call('previewFile', {
          url: getFileContent({ file_key }),
          fileName: opt?.page_title,
        });
      } else {
        call('fullScreenImage', {
          imageUrls: [getFileContent({ file_key })],
          imageX: 0,
          imageY: 0,
        });
      }
      break;
    }
    default:
  }
};

export const insPageLevelStorage = (count?: number) => {
  if (!isEmpty(count)) {
    if (typeof count !== 'number' || isNaN(count)) {
      console.error('insPageLevelStorage param error', count);
      return;
    }
    setLocalItem('__INSURANCE_PAGES_COUNT__', count, { expire: '62m' });
  } else {
    return getLocalItem('__INSURANCE_PAGES_COUNT__');
  }
};

export function getPromotionText(activities: IActivityInfoV2[]) {
  if (activities.length === 0) return '';
  const data = parseActivityInfoV2(activities);
  let discountText;
  let coinbackText;
  let cashbackText;
  if (data.discount) {
    discountText = formatMsg('discount.off', {
      discount: data.discount,
    });
  }
  if (data.cashback) {
    cashbackText = formatMsg('discount.cashback', {
      cashback: data.cashback,
    });
  }
  if (data.coinback) {
    coinbackText = formatMsg('discount.coinback', {
      coinback: data.coinback,
    });
  }

  return [discountText, cashbackText, coinbackText].filter((val) => val).join('+');
}

export function isValidDiscountObj(obj: any) {
  if (isEmpty(obj)) return false;
  return Object.keys(obj).some((val) => {
    if (obj[val]) {
      return true;
    }
    return false;
  });
}

export function getDaysDifference(date1: Date, date2: Date) {
  // 计算两个日期的时间戳差值（毫秒）
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());

  // 将毫秒转换为天数
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  return daysDiff;
}
